import React, { useState } from 'react';
import Axios from "axios";
import './AddEvent.css';

function AddEvent() {
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [eventDate, setEventDate] = useState();
    const [eventType, setEventType] = useState();
    const [eventMode, setEventMode] = useState();
    const [eventTime, setEventTime] = useState();
    const [eventPoster, setEventPoster] = useState();

    const submit = async (e) => {
        e.preventDefault();
        const newPost = {
                title: title,
                description: description,
                eventType: eventType,
                eventMode: eventMode,
                date: new Date(eventDate),
                time: eventTime,
                image: eventPoster
            }

        await Axios.post(
            "https://oscwebapi.herokuapp.com/api/posts/",
            newPost
        );
        
        alert("Request sent varify if the event added or not on oscvitap.org ");
    };

    return (
        <div className="page">
            <div className="notice">
                <span>
                    <h4>Dear EB Member,</h4><br></br>
                    <p>Welcome to OSC Events!</p><br></br>
                    <p>You can use this section to add events to be displayed on our newly deployed version of the website. Please note that, this app is still under development and will be updated with additional features in the coming days.</p><br></br>
                    <p>For now, you will not be able to update or delete any event once you have submitted this form, so please recheck before clicking the "Add Event" button.</p><br></br>
                    <p>If in case, you have made a mistake in the form, please contact @OSCAdmin or @Krish on Discord.</p><br></br>
                    <h4>
                        <p>Best Regards,</p>
                        <p>Administrator</p>
                    </h4>
                </span>
            </div>
            <h2 className="title">New Event</h2>
            <form className="form" onSubmit={submit}>
                <label htmlFor="event-title">Event Name</label>
                <input
                    id="event-title"
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                />

                <label htmlFor="event-description">Event Description (Limit: 200 word)</label>
                <textarea 
                    id="event-description" 
                    type="text" 
                    onChange={(e) => setDescription(e.target.value)}
                    style={{height:"200px"}}
                />

                <label htmlFor="event-date">Event Date</label>
                <input 
                    id="event-date" 
                    type="date" 
                    onChange={(e) => setEventDate(e.target.value)}
                />

                <label htmlFor="event-time">Event Time</label>
                <input 
                    id="event-time" 
                    type="time" 
                    onChange={(e) => setEventTime(e.target.value)}
                />

                <label htmlFor="event-type">Event Type (Hackathon, Guest Lecture, etc.)</label>
                <input 
                    id="event-type" 
                    type="text" 
                    onChange={(e) => setEventType(e.target.value)}
                />

                <label htmlFor="event-mode">Event Mode (Online / Offline)</label>
                <input 
                    id="event-mode" 
                    type="text" 
                    onChange={(e) => setEventMode(e.target.value)}
                />

                <label htmlFor="event-poster">Image url</label>
                <input 
                    id="event-poster" 
                    type="url" 
                    onChange={(e) => setEventPoster(e.target.value)}
                />

                <input className="btn-submit" type="submit" value="Add Event" />
            </form>
        </div>
    )
}

export default AddEvent;