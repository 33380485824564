import React from 'react';
import { Link } from "react-router-dom";
import AuthOptions from "./../auth/AuthOptions";
import logo from "./../../images/osceblogo.png";

function Header() {
    return (
        <header id="header">
            <Link className="title" to="/">
                <img className="logo" src={logo} ></img>
            </Link>
            <AuthOptions />
        </header>
    )
}

export default Header
