import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from "../../context/UserContext";
import AddEvent from '../layout/Events/AddEvent';

function Home() {
    const {userData} = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        if(!userData.user){
            history.push("/login");
        }
    });
    return (
        <div>
            <AddEvent />
        </div>
    )
}

export default Home
